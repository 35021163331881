<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  rules: {
    type: Array,
    default: [],
  },
  textFieldProps: {
    type: Object,
    default: () => ({}),
  },
  onlyShowValueOnChange: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input"]);

const menuOpen = ref(false);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const innerValue = computed({
  get() {
    return props.value;
  },
  set(newVal) {
    emit("input", newVal);
  },
});

const innerTextFieldValue = ref(null);
const shouldShowInitialValue = !props.onlyShowValueOnChange 
if (shouldShowInitialValue) {
  innerTextFieldValue.value = props.value;
}

const formattedDate = computed(() => {
  const dateNotEmpty =
    innerTextFieldValue.value !== null && innerTextFieldValue.value !== "";
  if (dateNotEmpty) {
    const date = new Date(innerTextFieldValue.value);
    const year = date.getFullYear();
    const day = date.getDate();
    const monthNumber = date.getMonth();
    return `${day} ${monthNames[monthNumber]} ${year}`;
  }
  return innerTextFieldValue.value;
});

function datePickerChanged(date) {
  menuOpen.value = false;
  innerTextFieldValue.value = date;
}
</script>

<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :label="label"
        readonly
        :value="formattedDate"
        :rules="rules"
        v-bind="{ ...props.textFieldProps, ...attrs }"
        v-on="on"
        clearable
      />
    </template>

    <v-date-picker
      no-title
      scrollable
      v-model="innerValue"
      @change="datePickerChanged"
      v-bind="$attrs"
    >
      <v-spacer />
      <v-btn text color="primary" @click="menuOpen = false"> Close </v-btn>
    </v-date-picker>
  </v-menu>
</template>
